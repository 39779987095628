import React, { useState } from "react"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import SEO from "../components/seo"
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageWrapper from "../PageWrapper";
import heroSvg from '../img/undraw/undraw_cloud_files_wmo8.svg';
import './demo-request.scss';
import { useFormik } from 'formik';

// TODO: MOVE THIS TO HELPERS AND REPLACE IN contact.js PAGE ALSO
function encode( data ){
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

function Hero({ bgImage }){

    const [ response, setResponse ] = useState({
        show: false,
        class: "",
        message: ""
    });

    const formik = useFormik({
        validateOnMount: true,
        initialValues: {
            name: "",
            email: "",
            message: ""
        },
        onSubmit: (values, { resetForm })=>{

            console.log( "Submitting message..." );

            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ 
                    "form-name": "request-demo-form", 
                    ...values,
                    "Request-Type" : "Request a Demo" 
                })
              })
                .then(( response ) => {

                    if ( response.ok && response.status === 200 ){

                        resetForm();                        
                        setResponse({ show: true, class: "success", message: "Thank you! Your request has been received. A representative from the company will get in touch with you shortly." });
                    } else {

                        throw new Error("Form submission failed");

                    }

                })
                .catch(error => {

                    console.log( "Error:", error );
                    setResponse({ show: true, class: "danger", message: "Something went wrong. Please try again." });
                });

        },
        validate: values => {
            let errors = {}
            if ( !values.name ){
                errors.name = "Required";
            }
            if ( !values.email ){
                errors.email = "Required";
            }
            if ( !values.message ){
                errors.message = "Required";
            }
            return errors;
        }
    })

    const disabledStyle = {
        opacity: 0.3,
        backgroundColor: "gray !important"
    }

    return (

        <>
            <div className="header-hero demo-request--header-hero bg_cover d-lg-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 zindex-100">
                            <div className="header-hero-content">
                                <h1 className="hero-title">REQUEST A<b> DEMO</b></h1>

                                {/* FORM */}
                                <form 
                                    onSubmit={formik.handleSubmit} 
                                    id="request-demo-form"
                                    name="request-demo-form"
                                    data-netlify="true"
                                    data-netlify-honeypot="email_address"
                                >
                                    <p className="hidden" hidden>
                                        <label>Don&apos;t fill this out if you&apos;re human: <input name="email_address" /></label>
                                    </p>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="contact-form mt-45">
                                                <input
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name} 
                                                    type="text"
                                                    name="name"
                                                    placeholder="Full Name" />
                                            </div> 
                                        </div>
                                        <div className="col-md-6">
                                            <div className="contact-form mt-45">
                                                <input
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email} 
                                                    type="email" 
                                                    name="email" 
                                                    placeholder="Email" />
                                            </div> 
                                        </div>
                                        <div className="col-md-12">
                                            <div className="contact-form mt-4">
                                                <textarea 
                                                    onChange={formik.handleChange}
                                                    value={formik.values.message} 
                                                    name="message"
                                                    placeholder="Message..."></textarea>
                                            </div> 
                                        </div>
                                        <p className="form-message"></p>
                                        <div className="col-md-12">
                                            <div className="contact-form mt-4 d-flex">
                                                <button 
                                                    disabled={ !formik.isDirty && formik.isValid ? false : true }
                                                    style={ !formik.isValid ? disabledStyle : {} } 
                                                    type="submit"
                                                    className="lexiqa-bg-green text-white main-btn btn-primary"
                                                >
                                                    Submit
                                                </button>
                                                <small className="ml-4">By clicking the Submit button, you agree that you have read and you acknowledged our <Link className="contact--privacy-link" to="/privacy">Privacy policy</Link>. Your personal information will not be sold, distributed or leased to third parties without your explicit consent.</small>
                                            </div> 
                                        </div>
                                    </div> 
                                </form>
                                {/* FORM */}
                                { response.show 
                                    && 
                                    <div className="email-response">
                                        <button className={ "btn py-3 pl-5 btn-" + response.class } onClick={()=> setResponse({ ...response, show: false }) }>
                                            <span className="mr-4">{ response.message } </span>
                                            <span className="ml-4 pr-2 email-response--close">&times;</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-hero-image d-flex align-items-center">
                    <div className="image p-5">
                        <img src={bgImage} alt="" />
                    </div>
                </div>
            </div>
        </>

    );

}
//                         { data, location }
const DemoRequestIndex = ( { data } ) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <PageWrapper>
            <Header component={ <Hero bgImage={ heroSvg } /> } title={ siteTitle } />
            <SEO title="Book a Demo - lexiQA" />
            <SEO description="Want to learn more about our QA checks and get answers to all your questions? Let’s talk!" />
            <Footer />
        </PageWrapper>
    )
}

export default DemoRequestIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
